(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:InscribedTeamsNumber
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('InscribedTeamsNumber', InscribedTeamsNumber);

  function InscribedTeamsNumber($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/phases/:phaseId/numberOfInscriptions', {phaseId: '@_phaseId'});
  }
}());
